<template>
 <el-dialog 
    width="900px"
    :visible.sync="dialogConfirmFormVisible"
    :close-on-click-modal="false"
    @close="close"
    class="confirm-form-diglog"
    append-to-body>
        <div slot="title" class="dialog-title">
            重新提交
        </div>

        <div class="page">
            
            <div class="dynacic-form">
               <dynamic-forms-for-archive :itemsReady="false" v-if="moduleId" ref='dynamic' :moduleId="moduleId" :modelId='modelId' :isEdit="true"></dynamic-forms-for-archive>
            </div>
            
        </div>


        <div  slot="footer" class="navigation clearfix" >
            <div class="left">
                <div class="item" >
                    <div class="text u-line-1" style="height: 60rpx;font-size: 34rpx">
                        <!-- <text class="goods-amount" v-if="false">￥{{(order.totalPrice).toFixed(2)}}</text>
                        <text class="goods-amount" v-if="order.totalPrice">￥{{(order.totalPrice).toFixed(2)}}</text> -->
                    </div>
                </div>
        
            </div>
            <div class="right">
                <el-button class="buy" type="primary" round 
                @click="goPaying">修改资料</el-button>
            </div>
        </div>

    </el-dialog>

</template>

<script>
import dynamicFormsForArchive from '@/components/DynamicFormsForArchive/index'

	import TimeUtil from '@/common/TimeUtil';
	import ConfigUtil from '@/common/Config'
	import CartService from '@/services/CartService'
	import AddressService from '@/services/AddressService'
	import OrderService from '@/services/OrderService'
	import GroupService from '@/services/GroupService'
	import InvoiceService from '@/services/InvoiceService'
	import OrderFunction from "@/mixins/Order"
	import MarketingSceneService from "@/services/MarketingSceneService";
	import ArticleService from "@/services/ArticleService";
	import uni from "@/services/UNIService";

	export default {
		components: {dynamicFormsForArchive},
		mixins: [OrderFunction],
		data() {
			return {
                dialogConfirmFormVisible:false,

				thatBang: false,
				order: {
					items: [{
						showGoodsImageUrlList: []
					}]
				},
				orderId:'',
				orderStatus:0,
				moduleId:"",
				modelId:'',
				deliverAddress: {},
				preferential:{}
			}
		},
		watch: {
			// deliverAddress(newValue) {
			// 	if (newValue) {
			// 		this.order.receiveAddressId = newValue.id;
			// 	}
			// }
		},
		events: {},
		onShow() {},
		// onLoad(options) {
			// console.log(options.orderId)
			// this.thatBang = this.isBang
			// this.orderId = options.orderId
			// this.doGetOrderById();
		// },
		methods: {

            close(){
				this.dialogConfirmFormVisible=false;
				this.thatBang= false;
				this.order={
					items: [{
						showGoodsImageUrlList: []
					}]
				}
				this.orderId='';
				this.orderStatus=0;
				// this.moduleId="group_8000000000000000";
				this.deliverAddress= {};
				this.preferential={};
            },
            init(orderId){
             
                this.dialogConfirmFormVisible=true;
				// this.moduleId:"group_8000000000000000";
                // this.thatBang = this.isBang;
                this.orderId = orderId;
                this.doGetOrderById();
            },


			async doGetOrderById(orderId) {
				var orderRes = await OrderService.doGetOrderById(this.orderId);
				if(orderRes.status){
					this.order = orderRes.data;
				for (var index in this.order.items) {
					var item = this.order.items[index];
				}
				//orderStatus:2 支付成功，待审核 orderStatus:3 审核驳回

					this.orderStatus = this.order.orderStatus
					if (this.order.auditState == 5) { //主体审核驳回
					this.moduleId = this.order.user.companyName;
					// this.orderStatus = 3
					console.log('userId',uni.getStorageSync('userId'))
					this.modelId = uni.getStorageSync('userId')
					}
					if (this.order.auditState == 15) { //合同审核驳回
					this.modelId = this.order.id
					// this.orderStatus = 3
					this.moduleId = "group_8000000000000000"
					}
				}
				
			},
			
			goPaying(){
			  	// console.log(this.orderStatus);
				// this.$refs.dynamic.submit(this.orderStatus)
				var that = this
				that.$refs.dynamic.doAU(that.orderId,async function(res) {
						console.log("au result:", res);
						if (res.data.status == 1) {
							that.doAudit()
						}
					})
			},
			async doAudit() {
				console.log("==========================222")
				var auditState = this.order.auditState == 5?0:10
				var res = await OrderService.doAudit(this.orderId, auditState)
				if(res.status==1){
					this.close();
					this.$emit('updateOrder')
				}
			
			},
		},

	
	}
</script>

<style scoped lang="scss">
$base:#2979ff;
$u-type-primary:#2979ff;

.confirm-form-diglog{

    /deep/.el-dialog{
        border-radius: 8px;
        overflow:hidden ;
    }
    /deep/.el-dialog__header{
      padding:0 0 0;
    }
    /deep/.el-dialog__body{
      padding:0 0 0;
    }

	/deep/.el-dialog__footer{
		padding:0 0 0;
	}
    .dialog-title{
        text-align: left;
        font-size:20px;
        color: #0f71ea;
        text-align: left;
        border-bottom:2px solid #e5e5e5;
        height: 60px;
        line-height: 60px;
        padding:0 20px;
    }

	.page{
		width: 100%;
		// height: 100%;
		background-color: #f6f6f6;

		/* 商品 */
		.goods-data{

			background-color: #FFFFFF;
			margin: 0 auto;

			.goods-title{
				display: flex;
				align-items: center;
				width: 100%;
				height: 80px;
				text{
					font-size: 26px;
					color: #222222;
				}
			}
			.goods-list{
				width: 100%;
				padding:10px 20px;
				.list{
					display: flex;
					align-items: center;
					width: 100%;
					height: 200px;
					margin:15px 0;
					
					.thumb{
						display: flex;
						align-items: center;
						width: 30%;
						height: 100%;
						image{
							width: 160px;
							height: 160px;
							border-radius: 10px;
						}
					}
					.item{
						width: 70%;
						height: 160px;
						.title{
							display: flex;
							flex-direction: column;
							// justify-content: center;
							width: 100%;
							height: 80px;
							.name{
								font-size: 28px;
								color: #222222;
							}
							.attr{
								font-size: 24px;
								color: #C0C0C0;
							}
						}
						.price-number{
							display: flex;
							align-items: center;
							justify-content: space-between;
							width: 100%;
							height: 60px;
							.price{
								display: flex;
								align-items: center;
								text{
									color: $base;
									font-weight: bold;
								}
								.min{
									font-size: 26px;
								}
								.max{
									font-size: 32px;
								}
							}
							.number{
								display: flex;
								align-items: center;
								text{
									font-size: 26px;
									color: #222222;
								}
							}
						}
						.tag{
							display: flex;
							align-items: center;
							width: 100%;
							height: 40px;
							text{
								padding: 2px 12px;
								color: $base;
								border: 2px solid $base;
								border-radius: 40px;
								font-size: 24px;
							}
						}
					}
				}
			}

		}

		.dynacic-form{
			margin:5px 0;
			padding:0 20px 20px;
			background: #fff;
		}
		
	}

	.navigation {
	
			width: 100%;
			display: flex;
			border: solid 2px #f2f2f2;
			background-color: #ffffff;
			padding: 10px 20px;

			.left {
				display: flex;
				width: 50%;
				
				.item {
					height: 42px;
					line-height: 42px;
					font-size: 22px;
					
					.goods-amount{
						font-size:24px;
					}
				}
			}
			.right {
				width: 50%;
				text-align: right;
			
				.buy {
					height: 40px;
					width: 120px;
					text-align:center;
					line-height:38px;
					padding:0 20px;
					background-color: $u-type-primary;
				}
			
			}
		}

}

/* 地址 */
.address-data{
	position: relative;
	padding: 10px 20px;
	background-color: #FFFFFF;
	overflow: hidden;
	
	.address-list{
		width: 100%;
		.list{
			display: flex;
			align-items: center;
			width: 100%;
			
			font-size: 22px;
			color: #555555;
			margin-right: 10px;
			.name{
				font-size:18px;
			}
			.mobile{
				font-size:18px;
			}
			.address{
				font-size: 20px;
				color: #222222;
				height: 40px;
				line-height: 40px;
			}
			.tips{
				font-size:18px;
				color: $base;
				height: 40px;
				line-height: 40px;
			}
		}
	}
}


/**
 * 地址提示
 */
.address-tips{
	position: fixed;
	left: 0;
	bottom: 100px;
	z-index: 100;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 60px;
	padding: 0 4%;
	background-color: #fef2ce;
	text{
		font-size: 26px;
		color: #fbbd08;
	}
}
.order-address{
	position: relative;
	margin-right:30px;
	margin-left:30px;
	font-size: 28px;
	outline: 0;
	background: #fff;
	overflow: hidden;
	margin-bottom: 8px;
}
.com-item-box{
	position: relative;
	font-size: 28px;
	outline: 0;
	box-shadow: 0 25px 25px rgba(0,0,0,.04);
	background: #fff;
	overflow: hidden;
	margin-bottom: 8px;
}
.order-group-btn {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: transparent;
	border: none;
}
.empty-address{
	position: relative;
	padding: 10px 4%;
	background-color: #FFFFFF;
	border-radius: 0 0 20px 20px;
	overflow: hidden;
	height: 100px;
	line-height: 80px;
	font-size: 32px;
	font-weight: 700;
}
.address {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.address-content {
	display: flex;
	align-items: center;
}

.address-content image {
	display: block;
	width: 40px;
	height: 40px;
	flex: none;
}

.address-text {
	color: #000000;
	font-size: 32px;
	font-weight: 700;
}
</style>

<style lang="scss">
.form-title{
	font-size:20px;
	color: #0f71ea;
	height: 60px;
	line-height: 60px;
	padding:0 20px;
}
.avatar-uploader{
    text-align: left;

    .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 148px;
        height: 148px;
        line-height: 148px;
        text-align: center;
    }
    .avatar {
        width: 148px;
        height: 148px;
        display: block;
    }
}
</style>
