<template>
    <div class="my-orders-box">
        <el-tabs v-model="activeName" class="orders-tabs" @tab-click="tabsclick" >
            <el-tab-pane :label="item.name" :name="item.title" v-for="(item,index) in orderStatuss" :key="index">
            </el-tab-pane>
        </el-tabs>

        <div class="page-box" v-if="pageList.length>0">
			<div class="order" v-for="(order, index) in pageList" :key="order.id" >
				<div class="top">
					<div class="left">
						<div class="store">{{ order.groupName }}</div>
                        <i class="el-icon-arrow-right"></i>
					</div>
					<div class="right">{{ orderStatus[order.orderStatus] }}</div>
				</div>
				<div class="goods-list">
					<su-goods-item v-for="(goods, itemIndex) in order.items" :key="itemIndex" :step='false' :goods="goods" :cartModel="true" :shareMoney="false" :showAdvisoryBtn="false"
					 v-if="goods.count>0"></su-goods-item>
				</div>
				<div class="item">
					<div class="content">
						<!-- <div class="title u-line-2">{{sendTypes[order.sendType]}}</div> -->
						<div class="type"></div>
						<div class="delivery-time" v-if="order.bookingDateLabel">预约时间 {{ order.bookingDateLabel}}</div>
					</div>
				</div>
				
				<div class="orderBtn">
					<div>
						<el-button class="mini-btn" type="default" size="small" @click="toDetail(order.id)">订单详情</el-button>
					</div>
					<!-- <div v-if="order.orderStatus >2 && order.expressNo">
						<el-button class="mini-btn" type="default" size="small"
						 @click.stop="toSearchExpress(order)">查看物流</el-button>
					</div> -->
					<div v-if="order.orderStatus < 0">
						<el-button class="mini-btn" type="default" size="small" @click.stop="toDelete(order)">删除订单</el-button>
					</div>
					<div v-if="order.orderStatus == 0||order.orderStatus ==1">
						<el-button class="mini-btn" type="default" size="small" @click.stop.prevent="toCancle(order)">取消订单</el-button>
					</div>
					<div v-if="order.orderStatus == 0">
						<el-button class="mini-btn " type="default" size="small" @click.stop.prevent="doPay(order)">付款</el-button>
					</div>
					<div v-if="order.orderStatus == 2 && order.runfundStatus==1">
						<el-button class="mini-btn" type="default" size="small"
						 @click.stop="toCompletedOrder(order)">提醒发货</el-button>
					</div>
				</div>
			</div>
            
			<!-- <u-gap height="80" bg-color="#F6F6F6"></u-gap> -->
			<!-- <u-loadmore :status="loadingStatus" :load-text="loadText" bgColor="#F6F6F6"></u-loadmore> -->
		</div>
        <su-empty v-else :txt="'暂无更多内容~~'"></su-empty>

		<!-- 订单详情 -->
		<order-detail  ref="orderDetail" @updateOrder="updateOrder"></order-detail>
		<!-- 订单付款 -->
		<pay-order ref="payOrder" @updateOrder="updateOrder"></pay-order>


    </div>
</template>
<script>
    import suGoodsItem from '@/components/su-goods-item/su-goods-item.vue'
    import suEmpty from '@/components/others/su-empty.vue'
	import orderDetail from '@/components/order/orderDetail.vue'
	import payOrder from '@/components/order/payOrder.vue'
	

	import ConfigUtil from '@/common/Config.js';
	import OrderService from '@/services/OrderService';
	import pagingMixin from '@/mixins/Paging';
	import OrderFunction from '@/mixins/Order';
    import uni from '@/services/UNIService'
	export default {
		components: {
            suGoodsItem,
            suEmpty,
			orderDetail,
			payOrder,
			
		},
		mixins: [pagingMixin, OrderFunction],
		data() {
			return {
		
                activeName:'one',

				config: {
					bgcolor: '#f9f9f9',
					navigationBarTextStyle: ''
				},

				orderStatus: {
					'0': '等待买家付款',
					'1': '等待卖家确认',
					'2': '卖家处理中',
					'3': '卖家已发货',
					'4': '交易成功',
					'-1': '交易关闭',
					'-2': '交易关闭',
					'-3': '门店取消订单',
					'5':'等待园区审核',
					'6':'园区审核驳回'
				},
				refundStatus: {
					2: '申请退款中',
					3: '退款中',
					4: '已退款',
					5: '卖家拒绝退款'
				},
				orderStatuss: [{
                        title:"one",
						name: '全  部',
						orderStatus: null,
						selected: true,
						refundStatus: 1
					},
					{
                        title:"two",
						name: '待确认',
						orderStatus: "1",
						payStatus: 0,
						selected: false,
						refundStatus: 1
					},
					{
                        title:"three",
						name: '待付款',
						orderStatus: "2",
						payStatus: 0,
						selected: false,
						refundStatus: 1
					},
					{
                        title:"four",
						name: '待发货',
						orderStatus: '2',
						payStatus: 1,
						selected: false,
						refundStatus: 1
					},
					{
                        title:"five",
						name: '待收货',
						orderStatus: 3,
						payStatus: 1,
						selected: false,
						refundStatus: 1
					},
					{
                        title:"six",
						name: '已完成',
						orderStatus: 4,
						selected: false,
						refundStatus: 1,
						payStatus: 1,
					},
					{
                        title:"seven",
						name: '售后',
						orderStatus: null,
						selected: false,
						refundStatus: '2,3,4',
						payStatus: 1,
					}
				],
				page: 1,
				count: 10,
				tabCur: 0,
				osm: {
					tradingAreaId: ConfigUtil.getBusinessTradingAreaId(),
					buyerId: uni.getStorageSync('userId'),
					orderType: 1,
					needItems: 1,
                    orderStatus:''
				}
			};
		},
		created() {
			// var orderStatusIndex = options.orderStatus;
			// if (!orderStatusIndex) {
			// 	orderStatusIndex = 0;
			// }
			
			// this.tabCur = +orderStatusIndex;
			// console.log(this.tabCur)
			this.tabChange(this.tabCur);
		},
		computed: {},
		watch: {},
		methods: {
			close(){
				this.tabChange(this.tabCur);
			},
			updateOrder(){
				this.tabChange(this.tabCur);
			},
            tabsclick(tab,e){
                this.tabChange(tab.index)
            },
			toDetail(id) {
				// uni.navigateTo({
				// 	url: 'OrderDetails?orderId=' + id
				// })
				console.log(id)
				this.$refs.orderDetail.init(id);
			},
			searchChange(val) {
				if (val) {
					//this.osm.orConditionModels = [{key:'buyerName',value:val,eqType:'like'},{key:'buyerPhone',value:val,eqType:'like'},{key:'orderGoodsContents',value:val,eqType:'like'}];
					this.osm.orConditionModels = [{
						key: 'goodsName',
						value: val,
						eqType: 'like'
					}];
				} else {
					this.osm.orConditionModels = null;
				}
				this.doPage();
			},
			tabChange(index) {
				this.tabCur = index;
				this.osm.orderStatus = this.orderStatuss[index].orderStatus;
				this.osm.refundStatus = this.orderStatuss[index].refundStatus;
				this.osm.payStatus = this.orderStatuss[index].payStatus;
				this.doPage();
			},
			dataFilter(orders) {
				orders.forEach(function(order) {
					if (order.orderStatus!=1 && order.payStatus == 0 && order.orderStatus > 0) {
						order.orderStatus = 0;
					}
					if (order.orderStatus== 2 ) {
						console.log(5<order.orderStatus<20)
						if(0<=order.orderStatus<20 &&order.orderStatus !=5  &&order.orderStatus !=15){
							order.orderStatus = 5;
						}
						if(order.auditState ==5||order.auditState==15){
							order.orderStatus = 6;
						}
						
					}
				});
				return orders;
			},
			async doPage() {
				this.pagePlugin = {
					api: OrderService.doPageNew,
					options: [
						this.osm,
						this.page,
						this.count
					]
				};
				await this.initializePageList();
			},
			doNextPage() {
				if (this.loadingStatus == 'nomore') {
					return;
				}
				this.loadingStatus = 'loading';
				this.currentPage += 1;
				this.doPageList();
			}

		}
	};
</script>
<style lang="scss" scoped>

$base:#2979ff;
$u-type-primary:#0f71ea;
$u-tips-color:#000;
$u-border-color:#000;
$u-type-info-dark:#000;


    .my-orders-box{
        width:100%;
        min-height:600px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        border-radius: 4px;

        .orders-tabs{
            /deep/.el-tabs__item{
                padding:0 20px;
            }
        }

        .page-box{
            padding: 0 30px 10px;
            .orderBtn {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                

                .mini-btn {
                    margin-left: 20px;
					font-size:14px;
                }
            }

            .order {
                width: 100%;
                background-color: #f5f5f5;
                margin: 20px auto;
                border-radius: 20px;
                box-sizing: border-box;
                padding: 20px;
                font-size: 24px;
                cursor: pointer;
                .top {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 15px;
                    .left {
                        display: flex;
                        align-items: center;
                        .store {
                            margin: 0 10px 0 0;
                            font-size: 20px;
                            font-weight: bold;
                            color:#666666;
                        }
                    }
                    .right {
                        color: $u-type-primary;
                        min-width: 180px;
                        text-align: right;
                        font-size:16px;
                    }
                }
                .item {
                    display: flex;
                    margin: 0 0 0;
                    .left {
                        margin-right: 20px;
                        image {
                            width: 200px;
                            height: 200px;
                            border-radius: 10px;
                        }
                    }
                    .content {
                        .title {
                            font-size: 36px;
                            line-height: 50px;
                        }
                        .type {
                            margin: 10px 0;
                            font-size: 24px;
                            color: $u-tips-color;
                        }
                        .delivery-time {
                            color: #e5d001;
                            font-size: 24px;
                        }
                    }
                    .right {
                        margin-left: 10px;
                        padding-top: 20px;
                        text-align: right;
                        .decimal {
                            font-size: 24px;
                            margin-top: 4px;
                        }
                        .number {
                            color: $u-tips-color;
                            font-size: 24px;
                        }
                    }
                }
                .total {
                    margin-top: 20px;
                    text-align: right;
                    font-size: 24px;
                    .total-price {
                        font-size: 32px;
                    }
                }
                .bottom {
                    display: flex;
                    margin-top: 40px;
                    padding: 0 10px;
                    justify-content: space-between;
                    align-items: center;
                    .btn {
                        line-height: 52px;
                        width: 160px;
                        border-radius: 26px;
                        border: 2px solid $u-border-color;
                        font-size: 26px;
                        text-align: center;
                        color: $u-type-info-dark;
                    }
                    .evaluate {
                        color: $u-type-primary;
                        border-color: $u-type-primary;
                    }
                }
            }
            .centre {
                text-align: center;
                margin: 200px auto;
                font-size: 32px;
                image {
                    width: 164px;
                    height: 164px;
                    border-radius: 50%;
                    margin-bottom: 20px;
                }
                .tips {
                    font-size: 24px;
                    color: #999999;
                    margin-top: 20px;
                }
                .btn {
                    margin: 80px auto;
                    width: 200px;
                    border-radius: 32px;
                    line-height: 64px;
                    color: #ffffff;
                    font-size: 26px;
                    background: linear-gradient(270deg, rgba(249, 116, 90, 1) 0%, rgba(255, 158, 1, 1) 100%);
                }
            }
        }
    }







</style>