<template>
    <el-dialog 
    width="900px"
    :visible.sync="dialogOrderDetailVisible"
    :close-on-click-modal="false"
    @close="close"
    class="order-detail-diglog">
        <div slot="title" class="dialog-title">
            订单详情 : 
            <span style="margin-left: 10px;" v-if="order.refundStatus==1">{{ orderStatus[order.orderStatus] }}</span>
            <span style="margin-left: 10px;" v-if="order.refundStatus!=1">{{ refundStatus[order.refundStatus] }}</span>
        </div>
        <div class="page">
            <!-- 收货地址 -->
            <div class="shipping-address">
                
                <div class="name-phone">
                    <div class="name">{{deliverAddress.receiveName}}</div>
                    <div class="phone">{{deliverAddress.mobile}}</div>
                </div>
                <div class="address">
                    {{deliverAddress.simpleAddress}}
                </div>
            </div>

            <!-- 订单商品 -->
            <div class="order-goods">
                <div class="dialog-title">
                    商品信息
                </div>
                <div class="goods-list">
                    <div class="list" v-for="(item,index) in order.items" :key="index">
                        <div class="thumb">
                            <el-image
                                style="width: 120px; height: 120px"
                                :src="item.showGoodsImageUrlList[0]"
                                fit="cover"></el-image>
                        </div>
                        <div class="item">
                            <div class="title">
                                <div class="one-omit">{{item.goodsTitle}}</div>
                            </div>
                            <div class="num-size">
                                <span>数量：{{item.count}}</span>
                                <span v-if="false">颜色：黑色,XL,全国联保</span>
                            </div>
                            <div class="price">
                                ￥{{item.totalPrice}}
                            </div>

                            <el-button type="primary" size="small" class="contact-btn" @click="doCall">联系客服</el-button>
                        </div>
                    </div>
                </div>
                
            </div>

            <!-- 订单信息 -->
            <div class="order-info">
                <div class="info-list">
                    <div class="list">
                        <div class="title">订单编号:</div>
                        <div class="content">
                            <div>{{order.id}}</div>
                        </div>
                    </div>
                    <div class="list">
                        <div class="title">下单时间:</div>
                        <div class="content">
                            <div>{{order.orderDateLabel}}</div>
                        </div>
                    </div>
                   
                </div>
            </div>

            <!-- 订单明细 -->
            <div class="order-details" v-if="order.orderStatus >1">
                <div class="details-list">
                    <div class="list">
                        <div class="title">
                            <div>商品总额</div>
                        </div>
                        <div class="price">
                            ￥{{order.totalPrice}}
                        </div>
                    </div>
                    <div class="list action">
                        <div class="title">
                            <div>实付款：</div>
                        </div>
                        <div class="price">
                            ￥{{order.realPrice.toFixed(2)}}
                        </div>
                    </div>
                </div>
            </div>

            <!-- 底部按钮 -->
            <div slot="footer" class="dialog-footer">
                <!-- 在取消订单后 显示删除订单 -->
                <div class="del" v-if="order.orderStatus<0" @click="toDelete(order)">
                    <el-button  type="danger" size="small">删除订单</el-button>
                </div>
                <div class="btn" v-if="order.refundStatus==1">
                    <!-- 在未发货 未退款的时候显示取消订单 -->
                    <div class="del" v-if="0<order.orderStatus && order.orderStatus< 3" @click="toCancle(order)">
                        <el-button size="small">取消订单</el-button>
                    </div>
                    <!-- 在已确认未取消订单 已付款，被驳回的时候显示提交资料 -->
                    <div class="del" v-if="order.orderStatus == 2&&order.payStatus==1&&(order.auditState==5||order.auditState==15)" @click="reSubmit(order)">
                        <el-button class="action" type="primary" size="small">重新提交</el-button>
                    </div>
                    <!-- 在已确认未取消订单 未付款的时候显示确认支付 -->
                    <div class="del" v-if="order.orderStatus == 2&&order.payStatus==0" @click="doPay(order)">
                        <el-button class="action" type="primary" size="small">确认支付</el-button>
                    </div>
                    <!-- 在已发货 未退款的时候显示确认收货 -->
                    <div class="del" v-if="order.orderStatus == 3" @click="toCompletedOrder(order)">
                        <el-button class="action" type="primary" size="small">确认收货</el-button>
                    </div>
                </div>
            </div>
        </div>

        <confirm-form ref="confirmForm" @updateOrder="updateOrder"></confirm-form>

        	<!-- 订单付款 -->
		<pay-order ref="payOrder" @updateOrder="updateOrder"></pay-order>

    </el-dialog>
</template>

<script>
import confirmForm from '@/components/order/confirmForm.vue'
import payOrder from '@/components/order/payOrder.vue'

	import OrderService from '@/services/OrderService';
	import GroupService from '@/services/GroupService';
	import AddressService from '@/services/AddressService';
	import InvoiceService from '@/services/InvoiceService';
	import TimeUtil from '@/common/TimeUtil';
	import OrderFunction from '@/mixins/Order';

	export default {
		components: {confirmForm,payOrder},
        props:[],
		mixins: [OrderFunction],
		data() {
			return {
                dialogOrderDetailVisible:false,

				numList: [{
					name: '已下单'
				}, {
					name: '待卖家确认'
				}, {
					name: '待付款'
				}, {
					name: '待审核'
				}, {
					name: '待发货'
				}, {
					name: '交易成功'
				}, ],
				numIndex: 0,
				current: 0,
				thatBang: false,
				order: {
					items: [{
						showGoodsImageUrlList: []
					}]
				},
				deliverAddress: {},
				invoice: {},
				group: {},
				loading: false,
				orderStatus: {
					'0': '等待买家付款',
					'1': '等待卖家确认',
					'2': '卖家处理中',
					'3': '卖家已发货',
					'4': '交易成功',
					'-1': '交易关闭',
					'-2': '交易关闭',
					'-3': '门店取消订单',
					'5':'等待园区审核',
					'6':'园区审核驳回,请重新提交资料'
				},
				refundStatus: {
					2: '申请退款中',
					3: '退款中',
					4: '已退款',
					5: '卖家拒绝退款'
				},
				orderStatusImgs: {
					'0': 'https://www.suconnected.com/xcx/assets/bddshop/o_error.png',
					'1': 'https://www.suconnected.com/xcx/assets/bddshop/o_success.png',
					'2': 'https://www.suconnected.com/xcx/assets/bddshop/o_error.png',
					'3': 'https://www.suconnected.com/xcx/assets/bddshop/o_success.png',
					'4': 'https://www.suconnected.com/xcx/assets/bddshop/o_success.png',
					'-1': 'https://www.suconnected.com/xcx/assets/bddshop/o_error.png',
					'-2': 'https://www.suconnected.com/xcx/assets/bddshop/o_error.png',
					'-3': 'https://www.suconnected.com/xcx/assets/bddshop/o_error.png'
				},
				servicesAddress: '',
				invoiceList: [{
						title: '发票类型：',
						detail: '普通发票',
						checkInvoice: '查看发票'
					},
					{
						title: '发票抬头：',
						detail: '个人'
					},
					{
						title: '发票内容：',
						detail: '商品明细'
					},
					{
						title: '纳税人识别号：',
						detail: '9464312975462X'
					}
				],
				orderBtnGroup: [{
					name: '申请售后',
					selected: false
				}, {
					name: '评价晒单',
					selected: false
				}, {
					name: '再次购买',
					selected: true
				}],
				sendTypes: ["", '自营配送', '上门自提', '快递发货', '自动发券（到店核销'],
			};
		},
        watch: {
			// dialogOrderDetailVisible(newValue,oldName) {
        	// 	if(this.dialogOrderDetailVisible){
            //         this.init(this.orderId);
            //     }
			// },
		},
		methods: {
            init(orderId){
                this.dialogOrderDetailVisible=true;
                this.thatBang = this.isBang;
                this.orderId = orderId;
                this.doGetOrderById();
            },
            close(){
                this.dialogOrderDetailVisible=false;
                this.orderId= '';
                this.numIndex= 0;
				this.current= 0;
				this.thatBang= false;
				this.order= {
					items: [{
						showGoodsImageUrlList: []
					}]
				}
				this.deliverAddress= {};
				this.invoice= {};
				this.group= {};
                this.$emit('updateOrder');
            },
            updateOrder(){
                this.close();
            },

			chooseBtn(order) {
				this.orderBtnGroup.forEach(function(tag) {
					tag.selected = false;
					if (order.name === tag.name) {
						tag.selected = true;
					}
				});
				this.currentPage = 1;
				this.Loadmore = {
					searchLoading: false,
					searchLoadingCompleted: false,
					loadingdiv: '正在加载更多',
					completeddiv: '没有更多了'
				};
			},
			// doCall() {
			//   uni.makePhoneCall({
			//     phoneNumber: '4008639900'
			//   });
			// }
			async doCall() {
				this.group = await GroupService.doGetOneGroup(this.order.groupId);
				this.group.linkPhone = this.group.contact ? this.group.contact : this.group.phone;
				// uni.makePhoneCall({
				// 	phoneNumber: this.group.linkPhone + ''
				// })
                this.$confirm(this.group.linkPhone + '', '联系电话', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    
                }).catch(() => {
                            
                });
			},
			toGoodsDetail: function(goodsId) {
				uni.navigateTo({
					url: '/pages/goods/goods_detail?goodsId=' + goodsId
				});
			},

            reSubmit(order){
                // console.log(order.id)
                // uni.navigateTo({
                // 	url:'/pages/Order/ConfirmFrom?orderId=' + order.id
                // })
                this.$refs.confirmForm.init(order.id);
            },

			toUseCard(order) {
				console.log(order);
				console.log("hello:" + order.goodsCategoryId);
				if (order.goodsCategoryId == 16006002000000000 || order.goodsCategoryId == 196000000000000000 || order.goodsCategoryId ==
					207000000000000000) {
					console.log("hello:" + order.businessType);
					this.$refs.CouponQRcode.doShowModal(order);
					return;
				}
				uni.navigateTo({
					url: '/pages/order/order_use_card?virtualKey=' + order.virtualKey + '&virtualValue=' + order.virtualValue
				});
			},
			async doGetOrderById() {
				var orderRes = await OrderService.doGetOrderById(this.orderId);
				this.order = orderRes.data;
				switch (this.order.orderStatus) {
					case 1:
						this.numList[0].name = "已下单"
						break;
					case 2:
						this.numList[0].name = "已下单"
						this.numList[1].name = "已确认"
						this.numIndex = 1;
						if (this.order.payStatus == 1) {
							this.numList[2].name = "已付款"
							this.numIndex = 2;
							if (this.order.auditState ==20) {
								this.numList[3].name = "已审核"
								this.numIndex = 3;
							}else if(this.order.auditState == 5 ||this.order.auditState==15){
								this.orderStatus['2'] = "园区审核驳回,请重新提交资料";
							}else{
								this.orderStatus['2'] = "等待园区审核";
							}
						} else {
							this.orderStatus['2'] = "等待付款";
						}
						
						break;
					case 3:
						this.numList[0].name = "已下单"
						this.numList[1].name = "已确认"
						this.numList[2].name = "已付款"
						this.numList[3].name = "已审核"
						this.numList[4].name = "已发货"
						this.numIndex = 4;
						break;
					case 4:
						this.numList[0].name = "已下单"
						this.numList[1].name = "已确认"
						this.numList[2].name = "已付款"
						this.numList[3].name = "已审核"
						this.numList[4].name = "已发货"
						this.numList[5].name = "交易成功"
						this.numIndex = 5;
						break;
				}
				this.order.payDate = TimeUtil.formatTime(this.order.payDate, 'Y-M-D H:m:s')
				this.order.orderDateLabel = TimeUtil.formatTime(this.order.orderDate, 'Y-M-D H:m:s');
				this.order.refundDateLabel = TimeUtil.formatTime(this.order.refundDate, 'Y-M-D H:m:s');
				this.order.sendDateLabel = TimeUtil.formatTime(this.order.sendDate, 'Y-M-D H:m:s');
				this.order.completeDateLabel = TimeUtil.formatTime(this.order.completeDate, 'Y-M-D H:m:s');
				this.order.payDateLabel = TimeUtil.formatTime(this.order.payDate, 'Y-M-D H:m:s');
				for (var item of this.order.items) {
					if (!item.refundStatus) {
						item.refundStatus = 1;
					}
					if (item.showGoodsImageUrl) {
						item.showGoodsImageUrlList = item.showGoodsImageUrl.split(',');
					} else {
						item.showGoodsImageUrlList = ['https://splatformnew.oss-cn-shanghai.aliyuncs.com/zhiguoguo/goods_default.png'];
					}
					if (item.virtualKey) {
						var res = await OrderService.doGetCouponItem(item.virtualKey);
						item.status = res.data.status;
					}
				}
				//this.doGetInvoice();
				this.doGetAddress();
				if (this.order.servicesGroupId) {
					this.doGetOneGroup(this.order.servicesGroupId);
				}
				this.loading = false;
			},
			async doGetAddress() {
				if (this.order.receiveAddressId) {
					this.deliverAddress = await AddressService.doGet(this.order.receiveAddressId);
				}
			},
			async doGetInvoice() {
				if (!this.order.invoiceId || this.order.invoiceId == '') {
					return;
				}
				this.invoice = await InvoiceService.doGetInvoice(this.order.invoiceId);
			},
			async doGetOneGroup(groupId) {
				var group = await GroupService.doGetOneGroup(groupId);
				console.log(group);
				this.servicesAddress = group.name + '(' + group.address + ')';
			}, //获取卡的状态
			doCopy() {
				uni.setClipboardData({
					data: this.order.id,
					success(res) {}
				});
			},
			async callPhone() {
				this.group = await GroupService.doGetOneGroup(this.order.groupId);
				this.group.linkPhone = this.group.contact ? this.group.contact : this.group.phone;
				uni.makePhoneCall({
					phoneNumber: this.group.linkPhone + ''
				});
			}
		},
		mounted() {
            
			
		}
	};
</script>

<style scoped lang="scss">
$base:#2979ff;

.order-detail-diglog{

    /deep/.el-dialog{
        border-radius: 8px;
        overflow:hidden ;
    }

    /deep/.el-dialog__header{
      padding:0 0 0;
    }
    /deep/.el-dialog__body{
      padding:0 0 0;
    }

    .dialog-title{
        text-align: left;
        font-size:20px;
        color: #0f71ea;
        text-align: left;
        border-bottom:2px solid #e5e5e5;
        height: 60px;
        line-height: 60px;
        padding:0 20px;
    }

    .page{

        position: relative;
        width: 100%;
        height: 100%;
        // background-color: #f6f6f6;

        .shipping-address{
                width: 100%;
                // min-height: 200px;
                background-color: #FFFFFF;
                margin: 0 auto 0;
                padding:10px 0;
             
                .name-phone{
                    display: flex;
                    align-items: center;
                    padding:0 20px;
                    height: 40px;
                    font-weight: 600;
                    // border-bottom: 2px solid #E4E7ED;
                    .name{
                        height: 36px;
                        line-height: 36px;
                        margin-right: 20px;
                        font-size: 20px;
                        color: #222222;
                    }
                    .phone{
                        margin-top: 5px;
                        height: 30px;
                        line-height: 30px;
                        font-size: 18px;
                        color: #333;
                    }
                    
                }
                .address{
                    padding:0 20px;
                    display: flex;
                    height:36px;
                    line-height: 36px;
                    // height: 100px;
                    font-size: 16px;
                    color: #959595;
                    font-weight: 600;
                }
            }

            /* 订单商品 */
            .order-goods{
                width: 100%;
                background-color: #FFFFFF;
                border-top: 8px solid #E4E7ED;
                border-bottom: 8px solid #E4E7ED;

                .goods-title{
                    padding: 10px 4% 0;
                }
                .goods-list{
                    padding: 0 4%;
                    .list{
                    	position:relative;
                        display: flex;
                        align-items: center;
                        width: 100%;
                        padding:15px 0;
                        .thumb{
                            display: flex;
                            width: 120px;
                            height: 120px;
                            margin-right:15px;
                        }
                        .item{
                        	position:relative;
                            width: calc(100% - 135px);
                            height: 100%;
                            .title{
                                display: flex;
                                align-items: center;
                                width: 100%;
                                height: 40px;
                                
                                font-size: 22px;
                                color: #222222;
                                
                            }
                            .num-size{
                                display: flex;
                                align-items: center;
                                width: 100%;
                                height: 30px;
                                font-size: 18px;
                                color: #959595;
                                margin-right: 20px;
                                
                                span:last-child{
                                    margin-right: 0;
                                }
                            }
                            .price{
                                display: flex;
                                align-items: center;
                                width: 100%;
                                height: 50px;
                                font-size: 22px;
                                font-weight: bold;
                                color: #222222;
                                
                            }
                            .contact-btn{
								position:absolute;
								bottom:10px;
								right:10px;
								height:38px;
								line-height:36px;
								width:120px;
								padding:0 20px;
								font-size:16px;
                            }
                            
                        }
                    }
                }
                
            }

            /* 订单信息 */
                .order-info{
                    width: 100%;
                    background-color: #FFFFFF;
                    margin: 0 auto;
                    border-bottom: 8px solid #E4E7ED;
                    .info-list{
                       
                        .list{
                            display: flex;
                            align-items: center;
                            width: 100%;
                            height: 50px;
                            padding:0 20px;
                            border-bottom: 2px solid #f6f6f6;
                            .title{
                                font-size: 16px;
                                color: #959595;
                            }
                            .content{
                                display: flex;
                                align-items: center;
                                margin-left: 20px;
                                
                                    font-size: 16px;
                                    font-weight: bold;
                                    color: #222222;
                                
                            }
                        }
                        .list:last-child{
                            border:none;
                        }

                    }
                }

                /* 订单明细 */
                .order-details{
                    width: 100%;
                    background-color: #FFFFFF;
                    border-radius: 20px;
                    margin: 15px auto 0;
                    .details-list{
                    
                        .list{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 100%;
                            height: 44px;
                            border-bottom: 2px solid #f6f6f6;
                            padding:0 20px;
                            .title{
                                font-size: 20px;
                                color: #959595;
                            }
                            .price{
                                font-size: 20px;
                                font-weight: bold;
                            }
                            &:last-child{
                                border-bottom: none;
                            }
                        }
                        .action{
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                            .price{
                                font-size: 22px;
                                font-weight: bold;
                                color: $base;
                            }
                        }
                    }
                }

                .dialog-footer{
                    display: flex;
                    align-items: center;
                    justify-content:flex-end;
                    width: 100%;
                    height: 60px;
                    background-color: #FFFFFF;
                    // border-top: 2px solid #EEEEEE;
                    padding: 0 20px;
                    .del{
                        display: flex;
                        align-items: center;
                        font-size: 30px;
                        font-weight: bold;

                        .el-button{
                            margin: 0 0 0 5px;
                        }
                    }
                    .btn{
                        display: flex;
                        align-items: center;
                        .el-button{
                            font-size:16px;
                            margin: 0 0 0 5px ;
                        }
                       
                        .action{
                            background-color: $base;
                            color: #FFFFFF;
                        }
                    }
                }
    }
}









</style>