<template>
 <el-dialog 
    width="900px"
    :visible.sync="dialogPayOrderVisible"
    :close-on-click-modal="false"
    @close="close"
    class="pay-order-diglog"
	append-to-body>
        <div slot="title" class="dialog-title">
            付款  
        </div>

        <div class="page">
            <!-- 地址 -->
            <div class="order-tab" v-if="order.sendType==5">
                <div class="order-tab-item" :class="index === senTypeTabCur ? 'order-tab-item-active':''" v-for="(item,index) in senTypeTabList"
                :key="index" :id="index" @tap="senTypeTabSelect(index)">{{item.title}}</div>
            </div>
            <div :class="order.sendType==5 ? 'order-address' : 'com-item-box'">
                <!--	order-address	com-item-box-->
                <div class="order-group">

                    <div v-if="deliverAddress.simpleAddress">
                        <div class="address-data">
                            <div class="address-list" @click="onSkip('address')">
                                <div class="list">
                                    <div class="address">{{deliverAddress.simpleAddress}}</div>
                                </div>
                                <div class="list">
                                    <div class="name">{{deliverAddress.receiveName}}</div>
                                    <div class="mobile">{{deliverAddress.mobile}}</div>
                                </div>
                                <div class="list">
                                    <div class="tips">(如果快递不方便接收，您可以选择暂时寄存服务)</div>
                                </div>
                            </div>
                            
                        </div>
                    </div>

                    <!-- <uni-icons :size="20" class="uni-icon-wrapper" color="#666" type="arrowright" /> -->
                </div>
            </div>
            <!-- 商品 -->
            <div class="goods-data">
                <div class="dialog-title">
                    商品信息
                </div>
                <div class="goods-list">
                    <su-goods-item v-for="(goods, index) in order.items" :key="index" :goods="goods" :cartModel="true" :shareMoney="false"
                     :step='false'></su-goods-item>
                </div>
            </div>
   
			<div class="dynacic-form">
                <dynamic-forms-for-archive :itemsReady="false" v-if="moduleId" ref='dynamic' :moduleId="moduleId" :modelId='orderId' :isEdit="true"></dynamic-forms-for-archive>
            </div>

          
     
            
        </div>

		<div slot="footer" class="navigation clearfix">
			<div class="left">
				<div class="item" @click="onClick('phone')">
					支付：<span class="goods-amount" v-if="order.totalPrice">￥{{(order.totalPrice).toFixed(2)}}</span>
				</div>
			</div>
			<div class="right">
				<el-button class="buy" type="primary" round  @click="goPaying">立即支付</el-button>
			</div>
		</div>

    </el-dialog>

</template>

<script>
	import suGoodsItem from "@/components/su-goods-item/su-goods-item.vue"
	import dynamicFormsForArchive from '@/components/DynamicFormsForArchive/index'

	import TimeUtil from '@/common/TimeUtil';
	import ConfigUtil from '@/common/Config'
	import CartService from '@/services/CartService'
	import AddressService from '@/services/AddressService'
	import OrderService from '@/services/OrderService'
	import GroupService from '@/services/GroupService'
	import InvoiceService from '@/services/InvoiceService'
	import OrderFunction from "@/mixins/Order"
	import MarketingSceneService from "@/services/MarketingSceneService";
	import ArticleService from "@/services/ArticleService";

	export default {
		components: {suGoodsItem,dynamicFormsForArchive},
		mixins: [OrderFunction],
		data() {
			return {
				dialogPayOrderVisible:false,
				thatBang: false,
				order: {
					items: [{
						showGoodsImageUrlList: []
					}]
				},
				orderId: '',
				orderStatus: 0,
				moduleId: "group_8000000000000000",
				deliverAddress: {},
				preferential: {}
			}
		},
		watch: {
			dialogPayOrderVisible(newValue,oldName) {
        		// if(this.dialogPayOrderVisible){
                //     this.init(this.orderId);
                // }
			},
		},
		events: {},
		onShow() {},
		// onLoad(options) {
		// 	this.thatBang = this.isBang;
		// 	this.orderId = options.orderId;
		// 	this.doGetOrderById();
		// },
		methods: {
            close(){
				this.dialogPayOrderVisible=false;
				this.thatBang= false;
				this.order= {
					items: [{
						showGoodsImageUrlList: []
					}]
				}
				this.orderId= '';
				this.orderStatus= 0;
				this.moduleId="group_8000000000000000";
				this.deliverAddress= {};
				this.preferential= {};
				this.$emit('updateOrder');
            },
			init(orderId){
                this.dialogPayOrderVisible=true;
                this.thatBang = this.isBang;
                this.orderId = orderId;
                this.doGetOrderById();
            },

			// 获取订单信息
			async doGetOrderById() {
				var orderRes = await OrderService.doGetOrderById(this.orderId);
				this.order = orderRes.data;
				console.log(this.order);
				for (var index in this.order.items) {
					var item = this.order.items[index];

				}
				this.orderStatus = this.order.orderStatus
				this.order.payDate = TimeUtil.formatTime(this.order.payDate, 'Y-M-D H:m:s')
				this.order.orderDateLabel = TimeUtil.formatTime(this.order.orderDate, 'Y-M-D H:m:s');
				this.order.refundDateLabel = TimeUtil.formatTime(this.order.refundDate, 'Y-M-D H:m:s');
				this.order.sendDateLabel = TimeUtil.formatTime(this.order.sendDate, 'Y-M-D H:m:s');
				this.order.completeDateLabel = TimeUtil.formatTime(this.order.completeDate, 'Y-M-D H:m:s');
				this.order.payDateLabel = TimeUtil.formatTime(this.order.payDate, 'Y-M-D H:m:s');
				for (var item of this.order.items) {
					if (!item.refundStatus) {
						item.refundStatus = 1;
					}
					if (item.showGoodsImageUrl) {
						item.showGoodsImageUrlList = item.showGoodsImageUrl.split(',');
					} else {
						item.showGoodsImageUrlList = ['https://splatformnew.oss-cn-shanghai.aliyuncs.com/zhiguoguo/goods_default.png'];
					}

					if (item.virtualKey) {
						var res = await OrderService.doGetCouponItem(item.virtualKey);
						item.status = res.data.status;
					}
				}

				// 212008001000000000 判断商品分类是否为服务券产品
				if (orderRes.data.items && orderRes.data.items[0]['goodsCategoryId']=='212008001000000000') {
					// console.log('服务券产品');
					// 切换付款表单的类型
					this.moduleId = 'order_pay_212008001000000000';
				} else {
					this.moduleId = 'group_8000000000000000';
				}
				
				//this.doGetInvoice();
				this.doGetAddress();
				this.doPrePay();
				if (this.order.servicesGroupId) {
					this.doGetOneGroup(this.order.servicesGroupId);
				}

			},
			// 获取商家信息
			async doGetOneGroup(groupId) {
				var group = await GroupService.doGetOneGroup(groupId);
				console.log(group);
				this.servicesAddress = group.name + '(' + group.address + ')';
			}, 
			//获取卡的状态
			//获取下单需要支付的金额和扣除的积分金额
			
			async doPrePay() { 
				console.log("doPrePay......");
				var that = this;
				console.log(that.order.items)
				that.order.items.forEach(function(item) {
					var goods = {};
					goods.tradingAreaId = ConfigUtil.getBusinessTradingAreaId()
					goods.id = item.goodsId;
					goods.cartCount = item.count;
					goods.goodsPrice = item.goodsPrice;
					goods.totalPrice = item.totalPrice;
					goods.categoryId = item.goodsCategoryId;
					goods.goodsShopCategoryId = item.goodsShopCategoryId;
					goods.presentPrice = item.goodsPrice;
					goods.discountStatus = item.discountStatus;
					goods.groupId = that.order.groupId;
					that.order.goodss.push(goods);
					// goods.sendType = that.senTypeTabList[that.senTypeTabCur].sendType;
				});

				that.preferential = await MarketingSceneService.doGetMaximumDiscountScheme(this.order.items);
				if (that.preferential.goodss.length > 0) {
					that.counter = that.preferential.goodss.length;
					for (var i = 0; i < this.preferential.goodss.length; i++) {
						var goods = this.preferential.goodss[i];
						goods.shortDesc = "赠品";
						var cart = await CartService.doSetCount(null, goods.cartCount, goods);
						if (cart) {
							goods.cardId = cart.id;
							goods.cartCount = cart.count;
							that.order.items.push(goods);
							that.doPrePayAgain({});
						}
					}
				}

				var payTypes = {};
				if (that.preferential.realPrice > 0) {
					payTypes = {
						23: that.preferential.realPrice
					};
				}
				that.order.payTypes = payTypes;

			},
			goPaying() {
				// console.log(this.orderStatus);
				// this.$refs.dynamic.doUpdate()
				var that = this
				that.$refs.dynamic.doAU(that.orderId,async function(res) {
						console.log("au result:", res);
						if (res.data.status == 1) {
							that.doPayOrder()
						}
					})
				// that.$refs.dynamic.doAUDynamicFormItemValueRelations(this.orderId,
				// 	async function(res) {
				// 		console.log("au result:", res);
				// 		that.doPayOrder()
				// 	});
			},
			// combine(list) {
			// 	var that = this
			// 	that.$refs.dynamic.doAUDynamicFormItemValueRelations(that.orderId,
			// 		async function(res) {
			// 			console.log("au result:", res);
			// 			if (res.data.status == 1) {
			// 				that.doPayOrder()
			// 			}
			// 		});
			// },
			async doPayOrder() {
				var payOrder = await OrderService.doPayOrder(this.orderId)
				console.log('payOrder',payOrder)
				// if(payOrder){
				// 	uni.reLaunch({
				// 		url:'./MyOrderList'
				// 	})
				// }
				this.$emit('updateOrder');
				this.close();
			},
			async doGetAddress() {
				if (this.order.receiveAddressId) {
					this.deliverAddress = await AddressService.doGet(this.order.receiveAddressId);
					console.log(this.deliverAddress)
				}
			}
		},


	}
</script>

<style scoped lang="scss">
$base:#2979ff;
$u-type-primary:#2979ff;

.pay-order-diglog{

    /deep/.el-dialog{
        border-radius: 8px;
        overflow:hidden ;
    }
    /deep/.el-dialog__header{
      padding:0 0 0;
    }
    /deep/.el-dialog__body{
      padding:0 0 0;
    }

	/deep/.el-dialog__footer{
		padding:0 0 0;
	}
    .dialog-title{
        text-align: left;
        font-size:20px;
        color: #0f71ea;
        text-align: left;
        border-bottom:2px solid #e5e5e5;
        height: 56px;
        line-height: 56px;
        padding:0 20px;
    }

	.page{
		width: 100%;
		// height: 100%;
		background-color: #f6f6f6;

		/* 商品 */
		.goods-data{

			background-color: #FFFFFF;
			margin: 0 auto;

			.goods-title{
				display: flex;
				align-items: center;
				width: 100%;
				height: 80px;
				text{
					font-size: 26px;
					color: #222222;
				}
			}
			.goods-list{
				width: 100%;
				padding:10px 20px;
				.list{
					display: flex;
					align-items: center;
					width: 100%;
					height: 200px;
					margin:15px 0;
					
					.thumb{
						display: flex;
						align-items: center;
						width: 30%;
						height: 100%;
						image{
							width: 160px;
							height: 160px;
							border-radius: 10px;
						}
					}
					.item{
						width: 70%;
						height: 160px;
						.title{
							display: flex;
							flex-direction: column;
							// justify-content: center;
							width: 100%;
							height: 80px;
							.name{
								font-size: 28px;
								color: #222222;
							}
							.attr{
								font-size: 24px;
								color: #C0C0C0;
							}
						}
						.price-number{
							display: flex;
							align-items: center;
							justify-content: space-between;
							width: 100%;
							height: 60px;
							.price{
								display: flex;
								align-items: center;
								text{
									color: $base;
									font-weight: bold;
								}
								.min{
									font-size: 26px;
								}
								.max{
									font-size: 32px;
								}
							}
							.number{
								display: flex;
								align-items: center;
								text{
									font-size: 26px;
									color: #222222;
								}
							}
						}
						.tag{
							display: flex;
							align-items: center;
							width: 100%;
							height: 40px;
							text{
								padding: 2px 12px;
								color: $base;
								border: 2px solid $base;
								border-radius: 40px;
								font-size: 24px;
							}
						}
					}
				}
			}

		}

		/* 地址 */
		.address-data{
			position: relative;
			padding: 10px 20px;
			background-color: #FFFFFF;
			overflow: hidden;
			
			.address-list{
				width: 100%;
				.list{
					display: flex;
					align-items: center;
					width: 100%;
					color: #555555;
					margin-right: 10px;
					.name{
						font-size:20px;
						margin-right:20px;
						height: 32px;
						line-height: 28px;
						color: #222222;
					}
					.mobile{
						font-size:18px;
						height: 32px;
						line-height: 32px;
						color: #222222;
					}
					.address{
						font-size: 18px;
						color: #222222;
						height: 38px;
						line-height: 38px;
					}
					.tips{
						font-size:14px;
						color: $base;
						height: 30px;
						line-height: 30px;
					}
				}
			}
		}

		.dynacic-form{
			margin:5px 0;
			padding:0 20px 20px;
			background: #fff;
		}
		
	}

	.navigation {
	
			width: 100%;
			display: flex;
			border: solid 2px #f2f2f2;
			background-color: #ffffff;
			padding: 10px 20px;

			.left {
				display: flex;
				width: 50%;
				
				.item {
					height: 42px;
					line-height: 42px;
					font-size: 22px;
					
					.goods-amount{
						font-size:24px;
					}
				}
			}
			.right {
				width: 50%;
				text-align: right;
			
				.buy {
					height: 40px;
					width: 120px;
					text-align:center;
					line-height:38px;
					padding:0 20px;
					background-color: $u-type-primary;
				}
			
			}
		}

}





.order-address{
	position: relative;
	margin-right:30px;
	margin-left:30px;
	font-size: 28px;
	outline: 0;
	background: #fff;
	overflow: hidden;
	margin-bottom: 8px;
}
.com-item-box{
	position: relative;
	font-size: 28px;
	outline: 0;
	box-shadow: 0 25px 25px rgba(0,0,0,.04);
	background: #fff;
	overflow: hidden;
	margin-bottom: 8px;
}
.order-group-btn {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: transparent;
	border: none;
}
.empty-address{
	position: relative;
	padding: 10px 4%;
	background-color: #FFFFFF;
	border-radius: 0 0 20px 20px;
	overflow: hidden;
	height: 100px;
	line-height: 80px;
	font-size: 32px;
	font-weight: 700;
}
.address {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.address-content {
	display: flex;
	align-items: center;
}

.address-content image {
	display: block;
	width: 40px;
	height: 40px;
	flex: none;
}

.address-text {
	color: #000000;
	font-size: 32px;
	font-weight: 700;
}
</style>

<style lang="scss">
.form-title{
	font-size:20px;
	color: #0f71ea;
	height: 60px;
	line-height: 60px;
	padding:0 20px;
}
.avatar-uploader{
    text-align: left;

    .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 148px;
        height: 148px;
        line-height: 148px;
        text-align: center;
    }
    .avatar {
        width: 148px;
        height: 148px;
        display: block;
    }
}
.file-uploader{
	.el-upload-list{
		.el-upload-list__item-name{
			display: none;
		}
		.el-upload-list__item-status-label{
			display:none;
		}
		.el-progress{
			margin-top:-40px;
		}
		
	}
	.file-uplode-tit{
		color:#0f71ea;
	}

	.el-icon-upload{
		margin:5px 0 0;
		color:#ccc;
	}
}
</style>
